<template>
  <e6-vr-select
    is-title
    :data="data"
    ref="select"
    v-model="selectValue"
    :props="props"
    @change="change"
    :remote="true"
    :placeholder="placeholder"
    :title="title"
    @filterChange="filterChange"
    :search="true"
    :loading="loading"
    :multiple="multiple"
    clearable
    @clear="handleClear"
    :disabled="disabled"
  >
  </e6-vr-select>
</template>

<script>
import { queryMaterialList } from "@/api";
import { printError, get } from "@/utils/util";

export default {
  name: "remoteSearch",
  components: {},
  props: {
    value: {
      type: [String, Number, Array]
    },
    props: {
      type: Object,
      default: () => {
        return {
          id: "materialNo",
          label: "materialName"
        };
      }
    },
    placeholder: {
      type: String,
      default: "客户名称"
    },
    title: {
      type: String,
      default: "客户名称"
    },
    //是否可清除
    clearable: {
      type: Boolean
    },
    //是否可输可选
    enterable: {
      type: Boolean,
      default: false
    },
    //是否禁用
    disabled: {
      type: Boolean
    },
    //远程请求接口名
    queryListAPI: {
      type: Function,
      default: queryMaterialList
    },
    //获取后台返回参数的路径
    dataPath: {
      type: String,
      default: "data.array"
    },
    //是否多选
    multiple: {
      type: Boolean,
      default: false
    },
    //远程搜索时入参名称
    searchValueKey: {
      type: String,
      default: "materialName"
    },
    row: {
      type: Object,
      default: () => {}
    },
    searchData: {
      type: Object,
      default: () => {
        return {
          pageIndex: 1,
          pageSize: 200
        };
      }
    },
    //是否初始化值,处理单选回显问题
    defaultValue: {
      type: Object,
      default: () => {
        return {};
      }
    },
    //是否需要做初始化请求
    showInit: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      loading: false,
      data: [],
      defaultConfig: {} //单选时上一次选中的数据
    };
  },
  mixins: [],
  computed: {
    // 双向绑定数据值
    selectValue: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("input", val);
      }
    }
  },
  watch: {
    defaultValue: {
      immediate: true,
      handler(value) {
        console.log(value, "@@@@");
        if (
          Object.keys(value).length > 0 &&
          value[this.props.id] &&
          !this.multiple
        ) {
          //不为空
          this.defaultConfig = { ...this.defaultValue };
          if (!this.showInit) {
            this.handleShowValue(this.data);
          }
        }
      }
    }
  },
  methods: {
    //加载加载数据
    async loadData(val) {
      try {
        this.loading = true;
        let res = await this.queryListAPI({
          ...this.searchData,
          [this.searchValueKey]: val
        });
        //获取客户名称
        let data = this.getFreezeResponse(res, {
          path: this.dataPath,
          freeze: false
        });
        //处理单选回显逻辑
        this.handleShowValue(data);
      } catch (error) {
        printError(error);
      } finally {
        this.loading = false;
      }
    },
    // 远程搜索实现示例
    filterChange: _.debounce(async function(query) {
      if (query) {
        this.loadData(query);
      }
    }, 300),
    change(val, node) {
      if (!this.multiple) {
        this.defaultConfig = node;
      }
      // this.$emit("input", this.selectValue);
      this.$emit("change", this.selectValue, node, this.row);
    },
    handleClear() {
      // let value = this.multiple ? [] : this.selectValue;
      // this.$emit("input", value);
      this.$emit("clear");
    },
    //处理单选回显问题
    handleShowValue(data) {
      //处理单选回显逻辑
      if (Object.keys(this.defaultConfig).length > 0 && !this.multiple) {
        //不为空
        if (
          !data.some(item => {
            return item[this.props.id] == this.defaultConfig[this.props.id];
          })
        ) {
          data.unshift(this.defaultConfig);
        }
      }
      this.$nextTick(() => {
        this.data = data;
      });
    },
    /*
     ** 处理后台返回的数据，默认 code=1 时，根据路径获取 object 中的属性值
     */
    getFreezeResponse(
      object,
      { path = "result", defaultVal = [], freeze = true, code = ["OK"] } = {}
    ) {
      if (code.includes(object.code)) {
        return get(object, path, defaultVal, freeze);
      } else {
        return defaultVal;
      }
    }
  },
  created() {
    if (this.showInit) {
      this.loadData();
    }
  },
  mounted() {}
};
</script>
<style lang="scss" scoped></style>
