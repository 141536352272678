/*
 * @Description: 客服中心
 * @Author: ChenXueLin
 * @Date: 2021-11-10 14:20:21
 * @LastEditTime: 2022-07-20 17:30:57
 * @LastEditors: ChenXueLin
 */
import http from "e6-base-core/axios/config";
import { e3Api } from "@/config/api";
const {
  host = "",
  E3_BASE_BASIC = "",
  E3_BL_CALLCENTER = "",
  E3_AG = "",
  E3_BL_CORPORATION = "",
  E3_BASE_EXTAPI = "",
  E3_BL_EWOS = "", // 8802
  E3_BL_STE = "",
  E3_BASE_EXTAPI_TIANRUN = ""
} = e3Api;

/**********客服管理***************/
// 获取客服管理列表
export function getCallServiceList(data) {
  return http({
    url: `${host}${E3_BL_CALLCENTER}/service/management/listPage`,
    method: "post",
    data
  });
}
/*******回访管理*************/

// 获取回访问题列表
export function getQuestionList(data) {
  return http({
    url: `${host}${E3_BL_CALLCENTER}/question/listPage`,
    method: "post",
    data
  });
}
// 获取回访问题详情
export function getQuestionDetail(data) {
  return http({
    url: `${host}${E3_BL_CALLCENTER}/question/detail`,
    method: "post",
    data
  });
}
// 编辑回访问题
export function editQuestion(data) {
  return http({
    url: `${host}${E3_BL_CALLCENTER}/question/update`,
    method: "post",
    data
  });
}
// 更改问题排序
export function updateOrderNo(data) {
  return http({
    url: `${host}${E3_BL_CALLCENTER}/question/updateOrderNo`,
    method: "post",
    data
  });
}
// 添加回访问题
export function addQuestion(data) {
  return http({
    url: `${host}${E3_BL_CALLCENTER}/question/create`,
    method: "post",
    data
  });
}
// 批量删除问题
export function deleteQuestion(data) {
  return http({
    url: `${host}${E3_BL_CALLCENTER}/question/batchDelete`,
    method: "post",
    data
  });
}
// 批量启用/禁用问题
export function batchQuestion(data) {
  return http({
    url: `${host}${E3_BL_CALLCENTER}/question/batchOperation`,
    method: "post",
    data
  });
}
// 队列管理列表
// 获取队列列表
export function getQueueList(data) {
  return http({
    url: `${host}${E3_BL_CALLCENTER}/server/queue/listPage`,
    method: "post",
    data
  });
}
//设置队列
export function batchSetCustomerQueue(data) {
  return http({
    url: `${host}${E3_BL_CALLCENTER}/server/queue/batchSetCustomerQueue`,
    method: "post",
    data
  });
}
/******回访类型*************/

// 获取回访类型列表
export function getVisiteTypeList(data) {
  return http({
    url: `${host}${E3_BL_CALLCENTER}/config/listPage`,
    method: "post",
    data
  });
}
// 删除回访类型
export function delVisiteType(data) {
  return http({
    url: `${host}${E3_BL_CALLCENTER}/config/batchDelete`,
    method: "post",
    data
  });
}
// 新增回访类型
export function addVisiteType(data) {
  return http({
    url: `${host}${E3_BL_CALLCENTER}/config/create`,
    method: "post",
    data
  });
}
// 编辑回访类型
export function editVisiteType(data) {
  return http({
    url: `${host}${E3_BL_CALLCENTER}/config/update`,
    method: "post",
    data
  });
}
/*******回访池***************/

//分页获取初装回访列表
export function getVisiteList(data) {
  return http({
    url: `${host}${E3_BL_CALLCENTER}/revisit/newInstall/listPage`,
    method: "post",
    data
  });
}
//分页查询满意度回访列表
export function getSatisfactionList(data) {
  return http({
    url: `${host}${E3_BL_CALLCENTER}/revisit/satisfaction/listPage`,
    method: "post",
    data
  });
}
//分页获取维护回访列表
export function getMaintenanceList(data) {
  return http({
    url: `${host}${E3_BL_CALLCENTER}/revisit/maintenance/listPage`,
    method: "post",
    data
  });
}
/******客户服务信息*************/
//获取客户服务信息列表
export function getCustomerServiceList(data) {
  return http({
    url: `${host}${E3_BL_CALLCENTER}/customer/service/listPage`,
    method: "post",
    data
  });
}
//获取一级服务类型
export function getFirstType(data) {
  return http({
    url: `${host}${E3_BL_CALLCENTER}/customer/type/list`,
    method: "post",
    data
  });
}
//获取二级服务类型
export function getSecondType(data) {
  return http({
    url: `${host}${E3_BL_CALLCENTER}/customer/type/listChild`,
    method: "post",
    data
  });
}
//工单批量绑定服务详情
export function batchInsert(data) {
  return http({
    url: `${host}${E3_BL_CALLCENTER}//customer/service/batchInsert`,
    method: "post",
    data
  });
}
// 导出客户服务
export function exportCustomerServiceList(data) {
  return http({
    url: `${host}${E3_BL_CALLCENTER}/customer/service/export`,
    method: "post",
    data
  });
}
// 客户服务详情
export function getCustomerServiceDetail(data) {
  return http({
    url: `${host}${E3_BL_CALLCENTER}/customer/service/detail`,
    method: "post",
    data
  });
}
/******来去电电话查询*************/
//获取来去电列表
export function getCallRecord(data) {
  return http({
    url: `${host}${E3_BL_CALLCENTER}/call/record/listPage`,
    method: "post",
    data
  });
}
//删除来去电记录
export function deleteCallRecord(data) {
  return http({
    url: `${host}${E3_BL_CALLCENTER}/call/record/batchDelete`,
    method: "post",
    data
  });
}
/******服务类型管理*************/
//获取服务类型列表
export function getCustomerTypeList(data) {
  return http({
    url: `${host}${E3_BL_CALLCENTER}/customer/type/listPage`,
    method: "post",
    data
  });
}
//批量禁用或启用
export function batchCustomerType(data) {
  return http({
    url: `${host}${E3_BL_CALLCENTER}/customer/type/batchOperation`,
    method: "post",
    data
  });
}
//新增一级服务或二级服务
export function addCustomerType(data) {
  return http({
    url: `${host}${E3_BL_CALLCENTER}/customer/type/create`,
    method: "post",
    data
  });
}
//编辑一级服务或二级服务
export function updateCustomerType(data) {
  return http({
    url: `${host}${E3_BL_CALLCENTER}/customer/type/update`,
    method: "post",
    data
  });
}
/******短信下发*************/
//获取短信列表
export function getSmsList(data) {
  return http({
    url: `${host}${E3_BL_CALLCENTER}/sms/listPage`,
    method: "post",
    data
  });
}
//导出短信列表
export function exportSmsList(data) {
  return http({
    url: `${host}${E3_BL_CALLCENTER}/sms/export`,
    method: "post",
    data
  });
}
//添加短信
export function createSms(data) {
  return http({
    url: `${host}${E3_BL_CALLCENTER}/sms/create`,
    method: "post",
    data
  });
}

/******服务快捷填充*************/
//获取填充列表
export function getFillList(data) {
  return http({
    url: `${host}${E3_BL_CALLCENTER}/service/fill/listPage`,
    method: "post",
    data
  });
}
//批量删除填充信息
export function deleteFill(data) {
  return http({
    url: `${host}${E3_BL_CALLCENTER}/service/fill/delete`,
    method: "post",
    data
  });
}
//新增填充信息
export function createFill(data) {
  return http({
    url: `${host}${E3_BL_CALLCENTER}/service/fill/create`,
    method: "post",
    data
  });
}

//修改填充信息
export function updateFill(data) {
  return http({
    url: `${host}${E3_BL_CALLCENTER}/service/fill/update`,
    method: "post",
    data
  });
}
//获取填充信息详情
export function getFillDetail(data) {
  return http({
    url: `${host}${E3_BL_CALLCENTER}/service/fill/detail`,
    method: "post",
    data
  });
}
/******回复时限管理*************/
//分页查询回复时限列表
export function getReplyList(data) {
  return http({
    url: `${host}${E3_BL_CALLCENTER}/time/limit/listPage`,
    method: "post",
    data
  });
}
//批量删除回复时限
export function deleteReply(data) {
  return http({
    url: `${host}${E3_BL_CALLCENTER}/time/limit/delete`,
    method: "post",
    data
  });
}
//创建回复时限
export function createReply(data) {
  return http({
    url: `${host}${E3_BL_CALLCENTER}/time/limit/create`,
    method: "post",
    data
  });
}
/*******邮件下发*********/
//获取邮件列表接口
export function getEmailList(data) {
  return http({
    url: `${host}${E3_BASE_BASIC}/email/listPage`,
    method: "post",
    data
  });
}
//新增邮件接口
export function sendEmail(data) {
  return http({
    url: `${host}${E3_BASE_BASIC}/email/sendEmail`,
    method: "post",
    data
  });
}
/********来去电工作台**********/
//根据客户id获取客户信息
export function getDetailById(data) {
  return http({
    url: `${host}${E3_BL_CALLCENTER}/platform/getDetailById`,
    method: "post",
    data
  });
}
//根据客户电话获取客户信息
export function getDetailByPhone(data) {
  return http({
    url: `${host}${E3_BL_CALLCENTER}/platform/getDetailByPhone`,
    method: "post",
    data
  });
}
//获取名下场景
export function getUserVehicle(data) {
  return http({
    url: `${host}${E3_AG}/vehicle/listPage`,
    method: "post",
    data
  });
}
//获取名下设备
export function getUserEquip(data) {
  return http({
    url: `${host}${E3_AG}/equip/listPage`,
    method: "post",
    data
  });
}
//获取公司账号
export function getAccount(data) {
  return http({
    url: `${host}${E3_BASE_EXTAPI}/extapi/e6yun/listPage`,
    method: "post",
    data
  });
}
//获取免登录地址
export function getUrl(data) {
  return http({
    url: `${host}${E3_BASE_EXTAPI}/extapi/e6yun/getUrl`,
    method: "post",
    data
  });
}
//立即监控
export function monitor(data) {
  return http({
    url: `${host}${E3_BL_STE}/terminal/getMonitoringList`,
    method: "post",
    data
  });
}
//维护信息
export function getMaintain(data) {
  return http({
    url: `${host}${E3_BL_EWOS}/maintainFee/fixRecordListPage`,
    method: "post",
    data
  });
}
//获取通讯录
export function getAddressBook(data) {
  return http({
    url: `${host}${E3_BL_CORPORATION}/contact/listPageByCorpId`,
    method: "post",
    data
  });
}
//添加客服详情
export function addServiceRecord(data) {
  return http({
    url: `${host}${E3_BL_CALLCENTER}/customer/service/create`,
    method: "post",
    data
  });
}
//获取来去电服务记录
export function getCallService(data) {
  return http({
    url: `${host}${E3_BL_CALLCENTER}/customer/service/list`,
    method: "post",
    data
  });
}
//客户的来电记录
export function getMorePhone(data) {
  return http({
    url: `${host}${E3_BL_CALLCENTER}/call/record/getRecordListByCustomerId`,
    method: "post",
    data
  });
}
//获取坐席号
export function getPhoneExt(data) {
  return http({
    url: `${host}${E3_BL_CORPORATION}/user/getPhoneExt`,
    method: "post",
    data
  });
}
//获取音频地址
export function getVideoSrc(data) {
  return http({
    url: `${host}${E3_BASE_EXTAPI_TIANRUN}/record/getRecordPath`,
    method: "post",
    data
  });
}
// 呼出电话
export function callPhone(data) {
  return http({
    url: `${host}${E3_BASE_EXTAPI_TIANRUN}/record/callPhone`,
    method: "post",
    data
  });
}
//通话记录分页查询
export function getTelephoneRecord(data) {
  return http({
    url: `${host}${E3_BL_EWOS}/cdr/listPage`,
    method: "post",
    data
  });
}
//导出通话记录分页查询
export function exportTelephoneRecord(data) {
  return http({
    url: `${host}${E3_BL_EWOS}/cdr/export`,
    method: "post",
    data
  });
}
