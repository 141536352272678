/*
 * @Description:任务单管理
 * @Author: ChenXueLin
 * @Date: 2021-10-09 15:19:03
 * @LastEditTime: 2023-01-30 17:09:30
 * @LastEditors: ChenXueLin
 */
import http from "e6-base-core/axios/config";
import { e3Api } from "@/config/api";
const {
  host = "",
  E3_BL_EWOS = "",
  E3_BL_CORPORATION = "",
  E3_BL_CALLCENTER = "",
  E3_BASE_BASIC = "",
  E3_BL_SUPPLIER = "",
  E3_BL_STE = "",
  E3_AG = ""
} = e3Api;
// 查询全部工单
export function getAllPage(data) {
  return http({
    url: `${host}${E3_BL_EWOS}/cssTaskinfo/getAllPage`,
    method: "post",
    data
  });
}
// 查询全部工单列表
export function getAllTask(data) {
  return http({
    url: `${host}${E3_BL_EWOS}/cssTaskinfo/all/listPage`,
    method: "post",
    data
  });
}
// 查询大区片区数据
export function getListAreaAll(data) {
  return http({
    url: `${host}${E3_BL_EWOS}/area/listAreaAll`,
    method: "post",
    data
  });
}
// 查询全部任务单列表
export function getAllTaskList(data) {
  return http({
    url: `${host}${E3_AG}/task/task/listPage`,
    method: "post",
    data
  });
}
export function getTaskList(data) {
  return http({
    url: `${host}${E3_BL_EWOS}/cssTaskinfo/task/listPage`,
    method: "post",
    data
  });
}
// 查询异常任务单列表
export function getAbnormalTaskList(data) {
  return http({
    url: `${host}${E3_BL_EWOS}/cssTaskinfo/abnormal/listPage`,
    method: "post",
    data
  });
}
//分页查询全部调度任务
export function getScheduleList(data) {
  return http({
    url: `${host}${E3_BL_EWOS}/cssTaskinfo/schedule/listPage`,
    method: "post",
    data
  });
}
//查询调度任务详情
export function getScheduleDetail(data) {
  return http({
    url: `${host}${E3_BL_EWOS}/cssTaskinfo/schedule/get`,
    method: "post",
    data
  });
}
//查询调度信息详情
export function getScheduleInfoDetail(data) {
  return http({
    url: `${host}${E3_BL_EWOS}/cssTaskinfo/scheduleInfo/get`,
    method: "post",
    data
  });
}
//分页查询全部任务项
export function getAllTaskItemPage(data) {
  return http({
    url: `${host}${E3_BL_EWOS}/cssTaskinfo/getAllTaskItemPage`,
    method: "post",
    data
  });
}
//导出全部任务项
export function exportAllTaskItem(data) {
  return http({
    url: `${host}${E3_BL_EWOS}/taskExport/item/export`,
    method: "post",
    data
  });
}
//查询任务项详情
export function getTaskItemDetail(data) {
  return http({
    url: `${host}${E3_BL_EWOS}/cssTaskinfo/taskItem/get`,
    method: "post",
    data
  });
}
//获取超维保账单详情
export function getOwpBill(data) {
  return http({
    url: `${host}${E3_BL_EWOS}/bill/getOwpBill`,
    method: "post",
    data
  });
}
//选择支付方式并获取二维码
export function selectPayment(data) {
  return http({
    url: `${host}${E3_BL_EWOS}/bill/selectPayment`,
    method: "post",
    data
  });
}
//查询任务项设备安装详情列表
export function getInstallDetail(data) {
  return http({
    url: `${host}${E3_BL_EWOS}/cssTaskinfo/installDetail/list`,
    method: "post",
    data
  });
}
//查询任务项获取对应设备列表
export function getTaskItemEquip(data) {
  return http({
    url: `${host}${E3_BL_EWOS}/taskDetail/item/get`,
    method: "post",
    data
  });
}
//查询凭据
export function getCredential(data) {
  return http({
    url: `${host}${E3_BL_EWOS}/cssTaskinfo/taskItem/getCredential`,
    method: "post",
    data
  });
}
//获取组合信息
export function getCombinationList(data) {
  return http({
    url: `${host}${E3_BL_EWOS}/engineerWarehouse/getCombinationList`,
    method: "post",
    data
  });
}
//查询上报安装配件
export function queryReportMaterial(data) {
  return http({
    url: `${host}${E3_BL_EWOS}/engineerWarehouse/queryReportMaterial`,
    method: "post",
    data
  });
}
//设置上报安装配件
export function setReportMaterial(data) {
  return http({
    url: `${host}${E3_BL_EWOS}/engineerWarehouse/setReportMaterial`,
    method: "post",
    data
  });
}
//查询上报耗材
export function queryReportConsumeMaterial(data) {
  return http({
    url: `${host}${E3_BL_EWOS}/engineerWarehouse/queryReportConsumeMaterial`,
    method: "post",
    data
  });
}
//查询工程师名下设备
export function getEngineerEquip(data) {
  return http({
    url: `${host}${E3_BL_EWOS}/engineer/engineerEquip/listPage`,
    method: "post",
    data
  });
}
//查询设备编号设备
export function getAllEquip(data) {
  return http({
    url: `${host}${E3_BL_EWOS}/engineer/allEquip/listPage`,
    method: "post",
    data
  });
}
//寄装设备查询
export function sendListDeliver(data) {
  return http({
    url: `${host}${E3_BL_EWOS}/cssTaskinfo/item/sendListDeliver`,
    method: "post",
    data
  });
}
//灰度客户工程师名下设备查询
// export function queryEngineerAllEquip(data) {
//   return http({
//     url: `${host}${E3_BL_EWOS}/engineerWarehouse/queryEngineerAllEquip`,
//     method: "post",
//     data
//   });
// }
//灰度客户工程师名下设备查询
export function queryEngineerAllEquip(data) {
  return http({
    url: `${host}${E3_BL_EWOS}/engineerWarehouse/queryRepairEquipList`,
    method: "post",
    data
  });
}
//灰度客户客户名下设备查询
export function getClientAllEquip(data) {
  return http({
    url: `${host}${E3_BL_EWOS}/engineerWarehouse/getClientAllEquip`,
    method: "post",
    data
  });
}
//校验设备是否需要转移
export function checkAllEquip(data) {
  return http({
    url: `${host}${E3_BL_EWOS}/engineer/allEquip/check`,
    method: "post",
    data
  });
}
export function querySendBack(data) {
  return http({
    url: `${host}${E3_BL_EWOS}/cssTaskinfo/item/querySendBack`,
    method: "post",
    data
  });
}
//设备转移
export function equipUpdateOwnerAndHave(data) {
  return http({
    url: `${host}${E3_BL_EWOS}/engineer/allEquip/equipUpdateOwnerAndHave`,
    method: "post",
    data
  });
}
// //获取主体
// export function getFaultClassByParent(data) {
//   return http({
//     url: `${host}${E3_BL_EWOS}/faultClass/getFaultClassByParent`,
//     method: "post",
//     data
//   });
// }
//获取工程师名下sim卡
export function engineerSimList(data) {
  return http({
    url: `${host}${E3_BL_STE}/sim/sim/web/engineerSimList`,
    method: "post",
    data
  });
}
//查询客户名下设备
export function getCustormerEquip(data) {
  return http({
    url: `${host}${E3_BL_EWOS}/cssTaskinfo/item/listDeliver`,
    method: "post",
    data
  });
}
//查询车辆品牌
export function getCarBrand(data) {
  return http({
    url: `${host}${E3_BL_STE}/vehicle/brand/list`,
    method: "post",
    data
  });
}
//查询车牌颜色
export function getCarColor(data) {
  return http({
    url: `${host}${E3_BL_STE}/plate/color/list`,
    method: "post",
    data
  });
}
//查询故障原因
export function queryRepairSolutionList(data) {
  return http({
    url: `${host}${E3_BL_EWOS}/cssTaskinfo/item/queryRepairSolutionList`,
    method: "post",
    data
  });
}
//获取安装位置下拉框
export function equInsPlaceList(data) {
  return http({
    url: `${host}${E3_BL_EWOS}/equInsPlace/list`,
    method: "post",
    data
  });
}
//新装、加装-补录信息
export function newAddCollection(data) {
  return http({
    url: `${host}${E3_BL_EWOS}/cssTaskinfo/task/supplementInfo`,
    method: "post",
    data
  });
}
//获取设备绑定sim卡信息
export function getEquipBindInfo(data) {
  return http({
    url: `${host}${E3_BL_EWOS}/equip/getEquipBindInfo`,
    method: "post",
    data
  });
}
//中心识别码生成规则
export function generateCommids(data) {
  return http({
    url: `${host}${E3_BL_STE}/sim/generateCommid`,
    method: "post",
    data
  });
}
//退回到未分配
export function itemReturn(data) {
  return http({
    url: `${host}${E3_BL_EWOS}/cssTaskinfo/item/itemReturn`,
    method: "post",
    data
  });
}
//维修、巡检、重装、补录信息
export function otherTaskCollection(data) {
  return http({
    url: `${host}${E3_BL_EWOS}/cssTaskinfo/task/disassembleSupplementInfo`,
    method: "post",
    data
  });
}
//查询未确认的费用
export function getUnconfirmedFee(data) {
  return http({
    url: `${host}${E3_BL_EWOS}/maintainFee/listByTaskItem`,
    method: "post",
    data
  });
}
//确认费用
export function confirmFee(data) {
  return http({
    url: `${host}${E3_BL_EWOS}/maintainFee/batchConfirm`,
    method: "post",
    data
  });
}
//审核费用
export function checkedFee(data) {
  return http({
    url: `${host}${E3_BL_EWOS}/maintainFee/checked`,
    method: "post",
    data
  });
}
//查询费用条目
export function getMaintainFee(data) {
  return http({
    url: `${host}${E3_BL_EWOS}/maintainFee/listFeeCategory`,
    method: "post",
    data
  });
}
//查询属性配置默认值
export function getAttr(data) {
  return http({
    url: `${host}${E3_BL_STE}/terminal/attr/get`,
    method: "post",
    data
  });
}
//属性配置确认
export function setAttr(data) {
  return http({
    url: `${host}${E3_BL_EWOS}/cssTaskinfo/taskItem/attrConfigure`,
    method: "post",
    data
  });
}
//获取组合
export function getCombination(data) {
  return http({
    url: `${host}${E3_BL_EWOS}/cssTaskinfo/taskItem/getCombinationByItemId`,
    method: "post",
    data
  });
}
//属性配置
export function updateHostAttribute(data) {
  return http({
    url: `${host}${E3_BL_EWOS}/cssTaskinfo/taskItem/attrConfigure`,
    method: "post",
    data
  });
}
//获取属性配置*
export function getTerminalAttrConfigure(data) {
  return http({
    url: `${host}${E3_BL_EWOS}/cssTaskinfo/taskItem/getTerminalAttrConfigure`,
    method: "post",
    data
  });
}
//属性配置*
export function updateAttribute(data) {
  return http({
    url: `${host}${E3_BL_EWOS}/cssTaskinfo/taskItem/g7s/attrConfigure`,
    method: "post",
    data
  });
}
//获取传感器下拉框
export function getSensorList(data) {
  return http({
    url: `${host}${E3_BASE_BASIC}/sensor/appmanagement/list`,
    method: "post",
    data
  });
}
//异常完成调度任务/调度信息
export function abnormalFinish(data) {
  return http({
    url: `${host}${E3_BL_EWOS}/taskDetail/taskAbnormal/create`,
    method: "post",
    data
  });
}
//任务项异常完成
export function abnormalFinishItem(data) {
  return http({
    url: `${host}${E3_BL_EWOS}/taskDetail/task/abnormalFinish`,
    method: "post",
    data
  });
}
//获取通话记录
export function getHistoryCall(data) {
  return http({
    url: `${host}${E3_BL_EWOS}/cloudCall/getHistoryCallRecordsList`,
    method: "post",
    data
  });
}
//分页查询全部调度信息列表
export function getScheduleInfoList(data) {
  return http({
    url: `${host}${E3_BL_EWOS}/cssTaskinfo/scheduleInfo/listPage`,
    method: "post",
    data
  });
}
//分页查询全部调度信息列表新
export function getScheduleInfoListNew(data) {
  return http({
    url: `${host}${E3_AG}/scheduleInfo/listPage`,
    method: "post",
    data
  });
}
// 获取地区列表下拉框数据
export function getAreaSelect(data) {
  return http({
    url: `${host}${E3_BL_EWOS}/area/list`,
    method: "post",
    data
  });
}
// 获取项目列表下拉框数据
export function getProjectSelect(data) {
  return http({
    url: `${host}${E3_BL_EWOS}/project/list`,
    method: "post",
    data
  });
}
/********创建维修单**********/
// 服务单转任务单查询任务项设备
export function getHasEquip(data) {
  return http({
    url: `${host}${E3_AG}/equip/listPage`,
    method: "post",
    data
  });
}
// 分页查询场景信息
export function getSceneEquip(data) {
  return http({
    url: `${host}${E3_AG}/scene/listPageForEquip`,
    method: "post",
    data
  });
}
// 查询公司场景个数
export function getSceneNumber(data) {
  return http({
    url: `${host}${E3_AG}/scene/listCount`,
    method: "post",
    data
  });
}
// 查询一二级问题类型
export function getQuestion(data) {
  return http({
    url: `${host}${E3_BL_EWOS}/equiperrorreason/listChildren`,
    method: "post",
    data
  });
}
// 查询客户名称下拉框
export function getCorpMsg(data) {
  return http({
    url: `${host}${E3_BL_CORPORATION}/corpMsg/downList`,
    method: "post",
    data
  });
}
// 查询地址下拉框
export function getAddressData(data) {
  return http({
    url: `${host}${E3_BASE_BASIC}/address/downList`,
    method: "post",
    data
  });
}
// 查询地址下拉框
export function getAddressDownList(data) {
  return http({
    url: `${host}${E3_BASE_BASIC}/address/getAddressDownList`,
    method: "post",
    data
  });
}
// 任务单创建检测作业
export function checkCreateTask(data) {
  return http({
    url: `${host}${E3_BL_EWOS}/cssTaskinfo/create/check`,
    method: "post",
    data
  });
}
// 新建维修单
export function createRepairTask(data) {
  return http({
    url: `${host}${E3_BL_EWOS}/cssTaskinfo/repair/create`,
    method: "post",
    data
  });
}
/********创建巡检单**********/
//新建巡检单
export function createInspection(data) {
  return http({
    url: `${host}${E3_BL_EWOS}/cssTaskinfo/inspection/create`,
    method: "post",
    data
  });
}
/********创建拆卸单**********/
//新建拆卸单
export function createRemoveOrder(data) {
  return http({
    url: `${host}${E3_BL_EWOS}/cssTaskinfo/disassemble/create`,
    method: "post",
    data
  });
}
/********创建重装单**********/
//新建重装单
export function createReinstall(data) {
  return http({
    url: `${host}${E3_BL_EWOS}/cssTaskinfo/reload/create`,
    method: "post",
    data
  });
}

/********任务单详情**********/
// 获取任务单基本信息
export function getTaskBasicInfo(data) {
  return http({
    url: `${host}${E3_BL_EWOS}/taskDetail/basicInfo/get`,
    method: "post",
    data
  });
}
// 修改任务单基本信息
export function updateTaskDetailBasicInfo(data) {
  return http({
    url: `${host}${E3_BL_EWOS}/taskDetail/work/update`,
    method: "post",
    data
  });
}
// 获取---新装加装---任务单的任务项列表
export function getInstallActionItem(data) {
  return http({
    url: `${host}${E3_BL_EWOS}/taskDetail/install/listPage`,
    method: "post",
    data
  });
}
// 获取---三级分类名称
export function getThirdClassList(data) {
  return http({
    url: `${host}${E3_BASE_BASIC}/equip/classify/listThirdBySecondId`,
    method: "post",
    data
  });
}
// 获取---三级物料名称
export function getListByIds(data) {
  return http({
    url: `${host}${E3_BL_SUPPLIER}/product/listByIds`,
    method: "post",
    data
  });
}
// 设置物料名称
export function setMaterial(data) {
  return http({
    url: `${host}${E3_BL_EWOS}/taskDetail/item/setMaterial`,
    method: "post",
    data
  });
}
// 获取--维修---任务单的任务项列表
export function getRepairActionItem(data) {
  return http({
    url: `${host}${E3_BL_EWOS}/taskDetail/repair/list`,
    method: "post",
    data
  });
}
// 获取--重装---任务单的任务项列表
export function getReinstallActionItem(data) {
  return http({
    url: `${host}${E3_BL_EWOS}/taskDetail/reload/list`,
    method: "post",
    data
  });
}
// 获取--拆卸---任务单的任务项列表
export function getRemoveTaskActionItem(data) {
  return http({
    url: `${host}${E3_BL_EWOS}/taskDetail/removeTask/get`,
    method: "post",
    data
  });
}
// 获取--巡检点列表
export function checkAddressList(data) {
  return http({
    url: `${host}${E3_BL_EWOS}/taskDetail/check/addressList`,
    method: "post",
    data
  });
}
// 获取--巡检任务单的任务项列表
export function checkTaskList(data) {
  return http({
    url: `${host}${E3_BL_EWOS}/taskDetail/check/list`,
    method: "post",
    data
  });
}
// 创建巡检调度任务
export function addCheckPatchTask(data) {
  return http({
    url: `${host}${E3_BL_EWOS}/taskDetail/checkPatchTask/create`,
    method: "post",
    data
  });
}
//安装明细
export function installDetail(data) {
  return http({
    url: `${host}${E3_BL_EWOS}/taskDetail/installDetail/get`,
    method: "post",
    data
  });
}
// 统计各任务状态对应数量
export function getStatus(data) {
  return http({
    url: `${host}${E3_BL_EWOS}/taskDetail/installDetail/count`,
    method: "post",
    data
  });
}
// 指派工程师
export function assignEngineer(data) {
  return http({
    url: `${host}${E3_BL_EWOS}/taskDetail/assignV2`,
    method: "post",
    data
  });
}
// 更换工程师
export function changeEngineer(data) {
  return http({
    url: `${host}${E3_BL_EWOS}/taskDetail/changeAssign`,
    method: "post",
    data
  });
}
// 撤回工单
export function batchReturnItem(data) {
  return http({
    url: `${host}${E3_BL_EWOS}/cssTaskinfo/all/batchReturnItem`,
    method: "post",
    data
  });
}
//工单改约
export function batchChangeItem(data) {
  return http({
    url: `${host}${E3_BL_EWOS}/cssTaskinfo/all/batchChangeItem`,
    method: "post",
    data
  });
}
//关闭任务项
export function batchCloseItem(data) {
  return http({
    url: `${host}${E3_BL_EWOS}/cssTaskinfo/all/batchCloseItem`,
    method: "post",
    data
  });
}
// 创建调度任务
export function createPatchTask(data) {
  return http({
    url: `${host}${E3_BL_EWOS}/taskDetail/patchTask/create`,
    method: "post",
    data
  });
}
// 关闭任务单任务
export function closeTask(data) {
  return http({
    url: `${host}${E3_BL_EWOS}/cssTaskinfo/all/batchClose`,
    method: "post",
    data
  });
}
// 关闭任务单任务校验
export function closeTaskValid(data) {
  return http({
    url: `${host}${E3_BL_EWOS}/cssTaskinfo/all/verificationBatchCloseTask`,
    method: "post",
    data
  });
}
// 导出任务单
export function exportTaskList(data) {
  return http({
    url: `${host}${E3_BL_EWOS}/taskExport/task/export`,
    method: "post",
    data
  });
}
// 添加跟进时间
export function createFollowTime(data) {
  return http({
    url: `${host}${E3_BL_EWOS}/cssTaskinfo/follow/create`,
    method: "post",
    data
  });
}
// 添加服务详情
export function createService(data) {
  return http({
    url: `${host}${E3_BL_CALLCENTER}/customer/service/create`,
    method: "post",
    data
  });
}
//获取工单服务详情
export function getTaskService(data) {
  return http({
    url: `${host}${E3_BL_CALLCENTER}/customer/service/listByTaskId`,
    method: "post",
    data
  });
}
// 查询调度任务列表
export function getPatchTaskList(data) {
  return http({
    url: `${host}${E3_BL_EWOS}/taskDetail/patchTask/get`,
    method: "post",
    data
  });
}
// 获取出库信息
export function getOutWarehouseInfo(data) {
  return http({
    url: `${host}${E3_BL_EWOS}/outWarehouse/getOutWarehouseInfo`,
    method: "post",
    data
  });
}
// 获取快递信息
export function getExpressInfo(data) {
  return http({
    url: `${host}${E3_BL_EWOS}/outWarehouse/getExpressInfo`,
    method: "post",
    data
  });
}
// 查询调度信息列表
export function getPatchInfoList(data) {
  return http({
    url: `${host}${E3_BL_EWOS}/taskDetail/patchInfo/get`,
    method: "post",
    data
  });
}
// 调度任务加急、取消加急
export function urgent(data) {
  return http({
    url: `${host}${E3_BL_EWOS}/taskDetail/urgent/create`,
    method: "post",
    data
  });
}
// 导出调度任务
export function exportScheduleTask(data) {
  return http({
    url: `${host}${E3_BL_EWOS}/taskExport/scheduleTask/export`,
    method: "post",
    data
  });
}
// 调度任务查看异常完成原因
export function taskAbnormal(data) {
  return http({
    url: `${host}${E3_BL_EWOS}/taskDetail/taskAbnormal/get`,
    method: "post",
    data
  });
}
// 调度信息查看异常完成原因
export function taskInfoAbnormal(data) {
  return http({
    url: `${host}${E3_BL_EWOS}/taskDetail/abnormal/get`,
    method: "post",
    data
  });
}
//导出调度信息
export function exportSchedule(data) {
  return http({
    url: `${host}${E3_BL_EWOS}/taskExport/schedule/export`,
    method: "post",
    data
  });
}
//导出调度信息新
export function exportScheduleInfoList(data) {
  return http({
    url: `${host}${E3_AG}/export/exportScheduleInfoList`,
    method: "post",
    data
  });
}
// 查询任务单关联单据
export function associatedBills(data) {
  return http({
    url: `${host}${E3_BL_EWOS}/taskDetail/relationTask/get`,
    method: "post",
    data
  });
}
// 获取物料列表明细
export function getProductList(data) {
  return http({
    url: `${host}${E3_BL_EWOS}/taskDetail/product/list`,
    method: "post",
    data
  });
}
// 获取物料列表明细
export function getWorkProductMaterial(data) {
  return http({
    url: `${host}${E3_BL_EWOS}/product/getWorkProductMaterial`,
    method: "post",
    data
  });
}
// 获取工单物料,支持可替换物料
export function getMaterialWithReplace(data) {
  return http({
    url: `${host}${E3_BL_EWOS}/product/getMaterialWithReplace`,
    method: "post",
    data
  });
}
// 获取第一个组合列表
export function getFirstCombinationList(data) {
  return http({
    url: `${host}${E3_BL_EWOS}/taskDetail/item/getFirstCombinationList`,
    method: "post",
    data
  });
}
// 设置三级分类展示列表
export function getThirdClass(data) {
  return http({
    url: `${host}${E3_BL_EWOS}/taskDetail/thirdClass/list`,
    method: "post",
    data
  });
}
// 设置三级分类
export function createThirdClass(data) {
  return http({
    url: `${host}${E3_BL_EWOS}/taskDetail/thirdClass/create`,
    method: "post",
    data
  });
}
// 根据任务ID查询费用明细
export function getTaskFee(data) {
  return http({
    url: `${host}${E3_BL_EWOS}/maintainFee/listByTask`,
    method: "post",
    data
  });
}
// 根据任务项ID查询费用明细
export function getListByTaskItem(data) {
  return http({
    url: `${host}${E3_BL_EWOS}/maintainFee/listByTaskItem`,
    method: "post",
    data
  });
}
// 日常批量
export function addDailyBatch(data) {
  return http({
    url: `${host}${E3_BL_EWOS}/itemWarn/task/addDailyBatch`,
    method: "post",
    data
  });
}
// 日常非批量
export function cleanDailyBatch(data) {
  return http({
    url: `${host}${E3_BL_EWOS}/itemWarn/task/cleanDailyBatch`,
    method: "post",
    data
  });
}
