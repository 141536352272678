/*
 * @Description:资源管理
 * @Author: ChenXueLin
 * @Date: 2021-12-17 15:53:11
 * @LastEditTime: 2022-07-25 13:54:40
 * @LastEditors: ChenXueLin
 */
import http from "e6-base-core/axios/config";
import { e3Api } from "@/config/api";
const {
  host = "",
  E3_BL_SUPPLIER = "",
  E3_BL_EWOS = "" // 8802
} = e3Api;
//查询物料分类列表(弹框)
export function getMaterialClassifyList(data) {
  return http({
    url: `${host}${E3_BL_EWOS}/settle/getMaterialClassifyList`,
    method: "post",
    data
  });
}
//查询物料分类列表
export function getMaterialRelationListPage(data) {
  return http({
    url: `${host}${E3_BL_EWOS}/settle/getMaterialRelationListPage`,
    method: "post",
    data
  });
}
//导出物料分类列表
export function exportMaterialClassify(data) {
  return http({
    url: `${host}${E3_BL_EWOS}/settle/exportMaterialClassify`,
    method: "post",
    data
  });
}
//新建物料分类
export function saveMaterialClassify(data) {
  return http({
    url: `${host}${E3_BL_EWOS}/settle/saveMaterialClassify`,
    method: "post",
    data
  });
}
//编辑物料分类
export function updateMaterialClassify(data) {
  return http({
    url: `${host}${E3_BL_EWOS}/settle/updateMaterialClassify`,
    method: "post",
    data
  });
}
//删除物料分类
export function removeMaterialClassify(data) {
  return http({
    url: `${host}${E3_BL_EWOS}/settle/removeMaterialClassify`,
    method: "post",
    data
  });
}
//绑定物料分类
export function batchUpdateStatus(data) {
  return http({
    url: `${host}${E3_BL_EWOS}/settle/batchUpdateStatus`,
    method: "post",
    data
  });
}
//查询服务商
export function getServiceProviderListPage(data) {
  return http({
    url: `${host}${E3_BL_EWOS}/settle/getServiceProviderListPage`,
    method: "post",
    data
  });
}
//导出服务商
export function exportServiceProvider(data) {
  return http({
    url: `${host}${E3_BL_EWOS}/settle/exportServiceProvider`,
    method: "post",
    data
  });
}
//新建服务商
export function saveServiceProvider(data) {
  return http({
    url: `${host}${E3_BL_EWOS}/settle/saveServiceProvider`,
    method: "post",
    data
  });
}
//编辑服务商
export function updateServiceProvider(data) {
  return http({
    url: `${host}${E3_BL_EWOS}/settle/updateServiceProvider`,
    method: "post",
    data
  });
}
//批量编辑服务商
export function batchUpdateServiceProvider(data) {
  return http({
    url: `${host}${E3_BL_EWOS}/settle/serviceProvider/batchUpdate`,
    method: "post",
    data
  });
}
//查询服务价格列表
export function getServicePriceListPage(data) {
  return http({
    url: `${host}${E3_BL_EWOS}/settle/getServicePriceListPage`,
    method: "post",
    data
  });
}
//导出服务价格列表
export function exportServicePrice(data) {
  return http({
    url: `${host}${E3_BL_EWOS}/settle/exportServicePrice`,
    method: "post",
    data
  });
}
//导入服务价格列表
export function importServicePrice(data) {
  return http({
    url: `${host}${E3_BL_EWOS}/settle/importServicePrice`,
    method: "post",
    data
  });
}
//批量导入服务价格列表
export function importUpdateServicePrice(data) {
  return http({
    url: `${host}${E3_BL_EWOS}/settle/importUpdateServicePrice`,
    method: "post",
    data
  });
}
//新建服务价格
export function saveServicePrice(data) {
  return http({
    url: `${host}${E3_BL_EWOS}/settle/saveServicePrice`,
    method: "post",
    data
  });
}
//查询服务价格地址
export function getServicePriceAddress(data) {
  return http({
    url: `${host}${E3_BL_EWOS}/settle/getServicePriceAddress`,
    method: "post",
    data
  });
}
//查询服务价格物料
export function getServiceMaterial(data) {
  return http({
    url: `${host}${E3_BL_EWOS}/settle/getServiceMaterial`,
    method: "post",
    data
  });
}
//编辑服务价格
export function updateServicePrice(data) {
  return http({
    url: `${host}${E3_BL_EWOS}/settle/updateServicePrice`,
    method: "post",
    data
  });
}
//导出服务价格列表
export function getServiceLog(data) {
  return http({
    url: `${host}${E3_BL_EWOS}/log/getServiceLog`,
    method: "post",
    data
  });
}
export function queryCustomer(data) {
  return http({
    url: `${host}${E3_BL_SUPPLIER}/getBaseInfo/queryCustomer`,
    method: "post",
    data
  });
}
export function queryListPageMaterialClassifySpec(data) {
  return http({
    url: `${host}${E3_BL_EWOS}/settle/listPageMaterialClassifySpec`,
    method: "post",
    data
  });
}
export function deleteMaterialClassifySpec(data) {
  return http({
    url: `${host}${E3_BL_EWOS}/settle/deleteMaterialClassifySpec`,
    method: "post",
    data
  });
}
export function bindMaterialClassifySpec(data) {
  return http({
    url: `${host}${E3_BL_EWOS}/settle/bindMaterialClassifySpec`,
    method: "post",
    data
  });
}

export function updateMaterialClassifySpec(data) {
  return http({
    url: `${host}${E3_BL_EWOS}/settle/updateMaterialClassifySpec`,
    method: "post",
    data
  });
}
export function getAssignRuleList(data) {
  return http({
    url: `${host}${E3_BL_EWOS}/assign/rule/listPage`,
    method: "post",
    data
  });
}
export function createAssignRule(data) {
  return http({
    url: `${host}${E3_BL_EWOS}/assign/rule/create`,
    method: "post",
    data
  });
}
export function deleteAssignRule(data) {
  return http({
    url: `${host}${E3_BL_EWOS}/assign/rule/batchDelete`,
    method: "post",
    data
  });
}
export function getAssignRuleDetail(data) {
  return http({
    url: `${host}${E3_BL_EWOS}/assign/rule/detail`,
    method: "post",
    data
  });
}
export function exportAssignRule(data) {
  return http({
    url: `${host}${E3_BL_EWOS}/assign/rule/export`,
    method: "post",
    data
  });
}
