/*
 * @Description:
 * @Author: ChenXueLin
 * @Date: 2021-08-19 09:18:28
 * @LastEditTime: 2022-10-31 14:50:46
 * @LastEditors: ChenXueLin
 */
import http from "e6-base-core/axios/config";
import { e3Api } from "@/config/api";
const {
  host = "",
  E3_BASE_USER_SYSTEM = "", //用户
  E3_BASE_LOGIN = "", // 8802
  E3_BASE_BASIC = ""
} = e3Api;

// 登录
export function login(data) {
  return http({
    url: `${host}${E3_BASE_LOGIN}/user/login`,
    method: "post",
    data
  });
}
// ldap账号登录
export function ldapLogin(data) {
  return http({
    url: `${host}${E3_BASE_LOGIN}/user/ldapLogin`,
    method: "post",
    data
  });
}

// 飞书三方免登登录
export function qrCodeLogin(data) {
  return http({
    url: `${host}${E3_BASE_LOGIN}/user/feishuLogin`,
    method: "post",
    data
  });
}

// 获取菜单树结构
export function fetchMenuTree() {
  return http({
    url: `${host}${E3_BASE_USER_SYSTEM}/module/menuTree`,
    method: "POST"
    // url: "/menuTree",
    // method: "GET"
  });
}

// 获取路由
export function fetchAllMenu() {
  return http({
    url: `${host}${E3_BASE_USER_SYSTEM}/module/allMenu`,
    method: "POST"
    // url: "/allMenu",
    // method: "GET"
  });
}
// 获取资源树
export function fetchAllModuleTree() {
  return http({
    url: `${host}${E3_BASE_USER_SYSTEM}/module/list`,
    method: "POST"
    // url: "/allMenu",
    // method: "GET"
  });
}
// 新增资源
export function createModule(data) {
  return http({
    url: `${host}${E3_BASE_USER_SYSTEM}/module/create`,
    method: "POST",
    data
  });
}
// 修改资源
export function updateModule(data) {
  return http({
    url: `${host}${E3_BASE_USER_SYSTEM}/module/update`,
    method: "POST",
    data
  });
}
// 获取资源详情
export function getModule(data) {
  return http({
    url: `${host}${E3_BASE_USER_SYSTEM}/module/get`,
    method: "POST",
    data
  });
}
// 删除资源
export function deleteModule(data) {
  return http({
    url: `${host}${E3_BASE_USER_SYSTEM}/module/delete`,
    method: "POST",
    data
  });
}
// 获取动作列表
export function getOperationDownList(data) {
  return http({
    url: `${host}${E3_BASE_USER_SYSTEM}/operation/listIdAndNames`,
    method: "POST",
    data
  });
}

// 退出登录
export function signOut(data) {
  return http({
    url: `${host}${E3_BASE_LOGIN}/user/logout`,
    method: "post",
    data
  });
}
export function saveReleaseRecord(data) {
  return http({
    url: `${host}${E3_BASE_BASIC}/release/record/save`,
    method: "post",
    data
  });
}
export function getReleaseRecordList(data) {
  return http({
    url: `${host}${E3_BASE_BASIC}/release/record/listPage`,
    method: "post",
    data
  });
}
export function exportReleaseRecordList(data) {
  return http({
    url: `${host}${E3_BASE_BASIC}/release/record/export`,
    method: "post",
    data
  });
}
export function getReleaseRecordDetail(data) {
  return http({
    url: `${host}${E3_BASE_BASIC}/release/record/detail`,
    method: "post",
    data
  });
}
export function batchUpRelease(data) {
  return http({
    url: `${host}${E3_BASE_BASIC}/release/record/batchUpRelease`,
    method: "post",
    data
  });
}
export function batchDownRelease(data) {
  return http({
    url: `${host}${E3_BASE_BASIC}/release/record/batchDownRelease`,
    method: "post",
    data
  });
}
